<template>
	<h1>Year Format</h1>
	<div class="text-block bump-b-30">
		The year of an artwork is stored in the database as an array of absolute numbers, which is then
		interpreted by the frontend into formatted date string. This allows us to filter artworks by date, while
		simultaneously correctly displaying the period they were created in.
	</div>
	<div class="text-block bump-b-30">
		<div id="demo" class="structured-form two-cols">
			<FormYear ref="year" :debug="true" @blur="translate" />
			<div>
				<label class="small">Result</label>
				<div class="display">
					{{ demoResult }}
				</div>
			</div>
		</div>
	</div>

	<!-- Code -->
	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import displayYear from '@/use/DisplayYear'
			
export default {
	setup() {
		return { displayYear }
	}
}</code></pre>
		<pre v-highlightjs><code class="language-javascript options">year: [1917]				// 1917
year: [1915, null, true]	// ca. 1917
year: [1920,1930]			// 1920s
year: [1920, 1930, true]	// ca. 1920s
year: [190,2000]			// 20th Century</code></pre>
	</div>

	<!-- table -->
	<div id="translation-table" class="text-block bump-b-30">
		<div v-for="(year, i) in years" :key="i">
			<span
				><span
					v-if="Array.isArray(year)"
					class="code"
					v-html="
						Array.isArray(year)
							? `[${
									year[2]
										? year.map(y => (y || y === 0 ? y : 'null')).slice(0, 3)
										: year[1] || year[1] === 0
										? year.slice(0, 2)
										: year.slice(0, 1)
							  }]`
							: ''
					"
				></span>
				<h2 v-else class="title"><br />{{ year }}</h2></span
			><span v-if="Array.isArray(year)"
				>{{ Array.isArray(year) ? displayYear(year) : ''
				}}<span class="note" v-if="Array.isArray(year) && year[3]">{{ year[3] }}</span></span
			>
		</div>
	</div>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'
import displayYear from '@/use/DisplayYear'
import FormYear from '@/components/FormYear'

export default {
	name: 'DocMechanicsYearFormat',
	components: { FormYear },
	setup() {
		injectMetaData({
			title: 'Doc: Year Format',
		})
		return { displayYear }
	},
	data() {
		return {
			html: '{{ displayYear(year) }}',
			demoResult: '...',
			// prettier-ignore
			years: [
				'Regular Dates',
				[1917],
				[2000],
				[1000],
				[990, null, null, 'Years before 1000 are displayed with AD'],
				[-990],
				[-1000],
				[1965, 1967],
				[-750, -600],
				[-50, 80],

				'Decades',
				[1950, 1960],
				[1050, 1060],
				[990, 1000, null, 'Decades are ignored before the year 1000'],
				[-1050, -1060, null, 'Decades are also ignored BC'],

				'Centuries',
				[1900, 2000],
				[0, 100, null, 'Only the first century is displayed with AD'],
				[100, 200],
				[-300, -200],
				[300, 400],
				[1500, 1700],
				[-1700, -1500, null, 'The order for BC ranges is flipped for readability'],
				[-100, 200, null, 'Ranges can span across BC & AD'],

				'Approximations',
				[1917, null, true, 'Third parameter set to true will display circa prefix'],
				[1915, 1920, true],
				[-3000, null, true],
			]
		}
	},
	methods: {
		translate() {
			const data = this.$refs.year.data
			this.demoResult = displayYear(data.output)
		},
	},
}
</script>

<style src="../../../assets/css/form-items.scss" scoped lang="scss"></style>
<style scoped lang="scss">
#demo {
	// width:2.5rem;
	display: flex;
	flex-direction: row;
	gap: 0.4rem;
}
#demo .display {
	height: 0.4rem;
	line-height: 0.4rem;
	border: solid 0.01rem $black-15;
	border-radius: $br;
	padding: 0 0.13rem;
	min-width: 2.5rem;
}

#translation-table {
	max-width: none;
}
#translation-table > div {
	display: flex;
}
#translation-table > div > span {
	flex: 0 01.5rem;
	// background: pink;
}
#translation-table > div > span:nth-child(2) {
	flex: 1 1 0;
	// background: orange;
}
#translation-table > div > span.title {
	width: auto;
}
#translation-table .note {
	margin-left: 0.2rem;
	color: $black-30;
}
</style>
